import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategories } from '../../../app/categoriesSlice';
import { createCategoriesOption } from '../../../utils/categoryUtils';
import { navigationActionsName } from '../data/navigationData';
import { courseType, docType, videoType } from '../data/typeDocumentData';
import { getCategory } from '../menu/menuLevels/categoryManagement/services/category.service';
import { ICategory, ISubCatergoriesList } from '../models/Categories';
import { getHyperlinkByType } from '../resources/documentSlice';
import { IResource, IResourceCategory } from '../resources/model/layout';
import { subCategoryId } from '../resources/ResourceData';

export const useRecursiveSelect = (actionPin?: string) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [subcategoriesList, setSubcategoriesList] = useState<
    ISubCatergoriesList[]
  >([]);
  const [idValues, setIdValues] = useState('');
  const [counter, setCounter] = useState<number>(0);
  const categories = useSelector(selectCategories);

  const checkType = () => {
    let type;
    switch (actionPin) {
      case navigationActionsName.goToVideo:
        type = videoType;
        break;
      case navigationActionsName.goToDoc:
        type = docType;
        break;
      case navigationActionsName.goToCourse:
        type = courseType;
        break;
      default:
        type = '';
    }
    return type;
  };

  const checkTypeResource = checkType();

  const handleChangeRecursiveSelect = (value: string, name: string) => {
    if (name.startsWith(subCategoryId)) {
      let categoryId = Number(name.charAt(name.length - 1));
      if (categoryId === counter) {
        getNewCategories(value, counter + 1);
        setCounter(counter + 1);
      } else {
        let arrValues = idValues.split(',');
        let newArrValues = arrValues.slice(0, categoryId + 1);
        let newIdValue = '';
        for (const el of newArrValues) {
          newIdValue = newIdValue === '' ? el : newIdValue.concat(`,${el}`);
        }
        setIdValues(newIdValue);
        let newListSubcategories = subcategoriesList.slice(
          0,
          value === undefined ? categoryId : categoryId + 1
        );
        getNewCategories(
          value === undefined ? newArrValues[newArrValues.length - 1] : value,
          value === undefined ? categoryId : categoryId + 1,
          newListSubcategories,
          newIdValue
        );
        setCounter(categoryId + 1);
      }
    }
  };

  const getNewCategories = (
    value: string = '0',
    cnt?: number,
    categoriesList?: ISubCatergoriesList[],
    temporaryIdValues?: string,
    type?: string
  ) => {
    getCategory({ idCat: value, depth: 'max' }).then(_res => {
      const res = _res as ICategory;
      if (res.children && res.children.length) {
        let newElement = {
          subCategory: [
            {
              typeElement: 'select',
              label: t('SUBCATEGORY.LABEL'),
              name: `id_${cnt !== undefined ? cnt : counter}`,
              dataElement: {
                placeholder: t('SUBCATEGORY.LABEL'),
                type: 'text',
              },
              options: [
                {
                  label: t('SUBCATEGORY.PLACEHOLDER'),
                  value: '-1',
                },
                ...createCategoriesOption(res.children),
              ],
              col: { xs: 12 },
            },
          ],
        };
        if (categoriesList) {
          setSubcategoriesList([...categoriesList, newElement]);
        } else {
          let newArrList = subcategoriesList.filter(
            el => el.subCategory[0].name !== newElement.subCategory[0].name
          );
          setSubcategoriesList([...newArrList, newElement]);
        }
        if (
          actionPin === navigationActionsName.goToVideo ||
          actionPin === navigationActionsName.goToDoc
        ) {
          handleGetLinkByType(value, temporaryIdValues, type);
        }
      } else {
        if (!!categoriesList) {
          setSubcategoriesList(categoriesList);
        }
        handleGetLinkByType(value, temporaryIdValues, type);
      }
    });
  };

  const handleGetLinkByType = (
    value: string,
    temporaryIdValues?: string,
    type?: string
  ) => {
    let newValue =
      idValues === '' || idValues === value || temporaryIdValues === value
        ? String(value)
        : idValues.concat(`,${value}`);
    newValue =
      temporaryIdValues && temporaryIdValues !== value
        ? temporaryIdValues.concat(`,${value}`)
        : String(newValue);
    dispatch(getHyperlinkByType(newValue, type ? type : checkTypeResource));
    setIdValues(newValue);
  };

  const resetListSelect = () => {
    setSubcategoriesList([]);
    setIdValues('');
    setCounter(0);
  };

  const generateSelectSubcategories = (data: IResource, categoryId: string) => {
    // STA COSA NN POTRA' MAI FUNZIONARE
    let categoryName = categories.filter(
      category => category.id === Number(categoryId)
    );
    let valuesArray = Object.values(data.subCategory || []);
    const arr = valuesArray.length ? handleSubcategory(valuesArray) : undefined;
    return {
      category: {
        id: Number(categoryId),
        name: categoryName.length > 0 ? categoryName[0].name : '',
        subCategory: arr,
      },
    };
  };

  const handleSubcategory = (values: any[]): IResourceCategory | undefined => {
    let value = values[0];
    let categoryName = categories.filter(
      category => category.id === Number(value)
    );
    let newValues = values.slice(1);
    if (newValues.length > 0) {
      return {
        id: Number(value),
        name: categoryName.length ? categoryName[0].name : '',
        subCategory: handleSubcategory(newValues),
      };
    } else {
      return {
        id: Number(value),
        name: categoryName.length ? categoryName[0].name : '',
      };
    }
  };

  return {
    getNewCategories,
    subcategoriesList,
    handleChangeRecursiveSelect,
    resetListSelect,
    generateSelectSubcategories,
    setSubcategoriesList,
    handleGetLinkByType,
  };
};
