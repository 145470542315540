import omit from 'lodash.omit';
import { EvaluationType } from '../../../../hooks/useEvalutation';
import http from '../../../../utils/http';
import { IAction } from '../models/type';

const BASE_URL = `/animates`;

export const retriveAllParams = (): Promise<any> => http.get(`${BASE_URL}/action/params`);

export const retriveAllActions = (categoryId: number): Promise<IAction[]> =>
    http.get(`${BASE_URL}/${categoryId}/action`);

export const retriveOneActions = (id: string, categoryId: number): Promise<IAction> =>
    http.get(`${BASE_URL}/${categoryId}/action/${id}`);

export const postAction = (action: Partial<IAction>, categoryId: number): Promise<IAction> => {
    const newAction = omit(action, ['_id']);
    return http.post(`${BASE_URL}/${categoryId}/action`, newAction);
};

export const putAction = (action: Partial<IAction>, categoryId: number, id: string): Promise<any> => {
    const newAction = omit(action, ['_id']);
    return http.put(`${BASE_URL}/${categoryId}/action/${id}`, newAction);
};

export const deleteAction = (categoryId: number, id: string): Promise<any> =>
    http.delete(`${BASE_URL}/${categoryId}/action/${id}`);

export const getEvaluations = (categoryId: string) =>
    http.get<EvaluationType[]>(`${BASE_URL}/${categoryId}/action/evaluation`);
