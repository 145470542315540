import DetectionEvent from '@iad-os/emotion-detector/dist/src/models/DetectionEvent';
import http from '../../../../utils/http';
import { DetectionModelAPI } from '../models/DetectionModelAPI';
import { EmotionConfiguration } from '../models/EmotionConfiguration';

const BASE_PATH = `/detection`;
const CONFIGURATIONS_PATH = `/configurations`;

export const initDetection = (detection: DetectionModelAPI): Promise<DetectionModelAPI> => {
    detection.start = Date.now();
    return http.post<DetectionModelAPI>(`${BASE_PATH}`, detection);
};

export const sendDetectionEventMock = (_idDetection: any, detectionEvent: DetectionEvent) => {
    const endpoint = `${BASE_PATH}/${_idDetection}/event`;
    console.log(`call: ${endpoint} with:`, { ...detectionEvent, _idDetection });
};

export const sendDetectionEvent = (_idDetection: any, detectionEvent: DetectionEvent) => {
    const endpoint = `${BASE_PATH}/${_idDetection}/event`;
    // console.log(`call: ${ endpoint } with:`, { ...detectionEvent, _idDetection });
    //@ts-ignore
    return http.post(endpoint, { ...detectionEvent, _idDetection }, { NO_LOADER_FULLSCREEN: true });
};

export const getAllConfigurations = (): Promise<EmotionConfiguration[]> =>
    http.get(`${BASE_PATH}${CONFIGURATIONS_PATH}/all`);
