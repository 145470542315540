import { useSelector } from 'react-redux';
import {
  selectCategories,
  selectMicrogameList,
  selectSubCategoriesList,
} from '../../../app/categoriesSlice';
import { navigationActionsName } from '../data/navigationData';
import { selectHyperlinkList } from '../resources/documentSlice';

const useCategories = (action: string) => {
  const categories = useSelector(selectCategories);
  const getSubCatOption = useSelector(selectSubCategoriesList);
  const microgameList = useSelector(selectMicrogameList);
  const hyperlinkList = useSelector(selectHyperlinkList);

  const getCatOption = () => {
    return action === navigationActionsName.goToListGame ||
      action === navigationActionsName.goToMicroGame ||
      action === navigationActionsName.goToDoc ||
      action === navigationActionsName.goToVideo ||
      // action === navigationActionsName.goToResources ||
      action === navigationActionsName.goToCourse
      ? categories.filter(cat => cat.depth === 1)
      : categories;
  };

  const getResourceOption = () => {
    if (
      action === navigationActionsName.goToDoc ||
      action === navigationActionsName.goToVideo ||
      action === navigationActionsName.goToResources ||
      action === navigationActionsName.goToCourse
    ) {
      return hyperlinkList;
    }
    return [];
  };

  return {
    categories,
    getCatOption,
    getSubCatOption,
    microgameList,
    getResourceOption,
  };
};

export default useCategories;
