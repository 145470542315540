import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllConfigurations } from '../services/emotions.service';

export const useEnableEmotions = () => {
    const location = useLocation();
    const [enabledPaths, setEnabledPaths] = useState<string[]>([]);
    useEffect(() => {
        getAllConfigurations().then(configurations => {
            const enabledPaths = configurations.filter(config => config.trackOn).map(config => config.path);
            setEnabledPaths(enabledPaths);
        });
    }, []);

    return enabledPaths.includes(location.pathname);
};
