import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUser } from '../../../app/auth/authSlice';
import { IMe } from '../../../app/auth/model/User';
import { addErrorMessage, addSuccessMessage } from '../../../app/errors/errorSlice';
import { isDocOrVideoOrCourseOrResources } from '../../../utils/categoryUtils';
import { AnimateData } from '../animations/models/types';
import { getGameQuestionAnimates } from '../animations/services/animation.service';
import { ISkill } from '../game/gamePlatform/models/gameQuestionConfiguration';
import { getAllSkills } from '../game/gamePlatform/services/gameConfiguration.service';
import { getCategory } from '../menu/menuLevels/categoryManagement/services/category.service';
import { ICategory } from '../models/Categories';
import { IResource } from '../resources/model/layout';
import { getAllHyperlinks } from '../resources/services/resources.service';
import { CommonActions } from './components/CommonActions';
import { IAction, ITrigger, SubCategoryType } from './models/type';
import { deleteAction, postAction, putAction, retriveAllActions } from './service/animates-action.service';

function AnimatesActions({ categories }: { categories: ICategory[] }) {
    const { t } = useTranslation();
    const user: IMe | undefined = useSelector(selectUser);
    const [actions, setActions] = useState<IAction[]>([]);
    const [skills, setSkills] = useState<ISkill[]>([]);
    const [animationGames, setAnimationGames] = useState<AnimateData[]>([]);
    const [depthCategories, setDepthCategories] = useState<ICategory>();
    const [resourcesData, setResourcesData] = useState<IResource[]>([]);
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        getGameQuestionAnimates()
            .then(res => {
                setAnimationGames(res);
            })
            .then(() => getActions());
        getAllSkills(user?.preference.language || 'it', undefined, Number.parseInt(id)).then(res => setSkills(res));
        getCategory({ idCat: 0, depth: 'max' }).then(_res => {
            const res = _res as ICategory;
            setDepthCategories(res);
        });
        getResourcesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addElementHandler = () => {
        const item: any = {};
        item.categoryId = id;
        item.trigger = { repeat: false };
        setActions([...actions, item]);
    };

    function fixGoToId(data: IAction): string[] | undefined {
        //se esiste resourceId la inserisco nel campo goToId
        const arGotoId = data.resourceId ? [data.resourceId] : data.goToId ? [data.goToId as string] : undefined;
        return arGotoId;
    }

    function fixDataForSubmit(data: IAction): Partial<IAction> {
        const fixedTrigger = (data.trigger as ITrigger[]).map(item => {
            return item;
        })[0];

        //GOTOCATEGORY
        const actualGoToCategoryIdArray: number[] = [Number.parseInt(data.goToCategoryId as string)];
        //aggiungo subItemId se esiste all'array goToCategoryId
        //solo nel caso delle risorse
        if (isDocOrVideoOrCourseOrResources(data.goTo)) {
            data.subItemId && actualGoToCategoryIdArray.push(Number(data.subItemId));
        }

        const goToCategoryId = fixedArrayGoToCategoryId(actualGoToCategoryIdArray, data.category?.subCategory);
        //GOTOID
        const goToId = fixGoToId(data);

        const fixedData = {
            ...omit(data, ['goToCategoryId', 'subItemId', 'resourceId']),
            trigger: fixedTrigger,
            goToCategoryId,
            goToId,
        };
        //CONDITIONS
        if (data.conditions) {
            const fixedConditions = data.conditions.map(condition => {
                let fixedCondition;
                if (isEqual(condition.idQuestion, '-1')) {
                    fixedCondition = omit(condition, ['idQuestion']);
                } else {
                    fixedCondition = condition;
                }
                if (
                    condition.answers &&
                    condition.answers !== '-1' &&
                    condition.answers !== '[]' &&
                    condition.answers.length > 0
                ) {
                    const fixedAnswers: number[] = JSON.parse(`${condition.answers}`).map((answer: string) =>
                        Number.parseInt(answer)
                    );
                    return { ...fixedCondition, answers: fixedAnswers };
                } else {
                    return omit(fixedCondition, ['answers']);
                }
            });
            return { ...omit(fixedData, ['category']), conditions: fixedConditions };
        } else {
            return omit(fixedData, ['category']);
        }
    }

    function fixedArrayGoToCategoryId(goTocategoryIdArray: number[], father?: SubCategoryType): number[] {
        if (father) {
            const newArray = father.id ? [...goTocategoryIdArray, father.id] : goTocategoryIdArray;
            const children = father.subCategory;
            goTocategoryIdArray = fixedArrayGoToCategoryId(newArray, children);
        }

        return goTocategoryIdArray;
    }

    function getActions() {
        retriveAllActions(Number.parseInt(id)).then(res => setActions(res));
    }

    function getResourcesData() {
        getAllHyperlinks().then(res => setResourcesData(res));
    }

    function saveAction(action: IAction) {
        const fixedAction = fixDataForSubmit(action);

        if (fixedAction._id) {
            putAction(fixedAction, Number.parseInt(id), fixedAction._id)
                .then(res => {
                    dispatch(addSuccessMessage({ title: t('SUCCESSMSG.GENERICSAVE') }));
                    getActions();
                })
                .catch(err => dispatch(addErrorMessage({ title: t('ERRORSMSG.GENERICSAVE') })));
        } else {
            postAction(fixedAction, Number.parseInt(id))
                .then(res => {
                    dispatch(addSuccessMessage({ title: t('SUCCESSMSG.GENERICSAVE') }));
                    getActions();
                })
                .catch(err => dispatch(addErrorMessage({ title: t('ERRORSMSG.GENERICSAVE') })));
        }
    }

    function deleteActionHandler(action: IAction) {
        if (action._id) {
            deleteAction(action.categoryId, action._id).then(res => getActions());
        } else {
            setActions(actions.filter(item => item._id !== action._id));
        }
    }

    return (
        <CommonActions
            actions={actions}
            categories={categories}
            skills={skills}
            animationGames={animationGames}
            depthCategories={depthCategories}
            resourcesData={resourcesData}
            deleteAction={deleteActionHandler}
            submitAction={saveAction}
            addElement={addElementHandler}
        />
    );
}

export default AnimatesActions;
