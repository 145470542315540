import React from 'react';
import { Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IBtnList } from '../../../../app/auth/model/BtnList';
import ToolbarBtn from '../../../../components/shared/custom/sidebar/toolbarBtn/ToolbarBtn';
import TabsPaneV2 from '../../../../components/shared/design/tabs/TabsV2';
import AnimatesActions from '../../actions/AnimatesActions';
import Check from '../../check/Check';
import { ICategory } from '../../models/Categories';
import AnimationForm from '../adminSidebar/components/AnimationForm';
import { AnimateData } from '../models/types';

interface ISidebarContent {
    isEditMode: boolean;
    btnListLeft: IBtnList[];
    btnListRight: IBtnList[];
    btnListLeftCheck: IBtnList[];
    btnListRightCheck: IBtnList[];
    animationActionToolbarBtn: <T>(action: string, data?: T) => void;
    checkActionToolbarBtn: <T>(action: string, data?: T) => void;
    handleDataFormChange: (data: AnimateData) => void;
    animateData: AnimateData;
    isBtnClicked: boolean;
    isSubmitForm: boolean;
    setIsSubmitCheckForm: (isSubmit: boolean) => void;
    categories: ICategory[];
    tabBackground: JSX.Element;
}

function SidebarContent(props: ISidebarContent) {
    const {
        //isEditMode,
        btnListLeft,
        btnListRight,
        btnListLeftCheck,
        btnListRightCheck,
        animationActionToolbarBtn,
        checkActionToolbarBtn,
        animateData: selectedAnimation,
        handleDataFormChange,
        isBtnClicked,
        isSubmitForm,
        setIsSubmitCheckForm,
        categories,
        tabBackground,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <TabsPaneV2 defaultActiveKey="animation">
                <Tab eventKey="animation" title={t('APP.ANIMATION_TITLE')}>
                    <AnimationForm
                        animateData={selectedAnimation}
                        onChangeDataForm={handleDataFormChange}
                        isBtnClicked={isBtnClicked}
                        onToolbarAction={animationActionToolbarBtn}
                        toolbarData={{ left: btnListLeft, right: btnListRight }}
                        categories={categories}
                    />
                </Tab>
                <Tab eventKey="check" title={t('CHECK.TITLE')}>
                    <ToolbarBtn
                        btnListLeft={btnListLeftCheck}
                        btnListRight={btnListRightCheck}
                        clickAction={checkActionToolbarBtn}
                    />
                    <Check
                        selected={selectedAnimation}
                        submitForm={isSubmitForm}
                        options={{ setIsSubmitCheckForm: setIsSubmitCheckForm }}
                    />
                </Tab>
                <Tab eventKey="actions" title={t('APP.ACTION_TITLE')}>
                    <AnimatesActions categories={categories} />
                </Tab>
                <Tab eventKey="background" title={t('APP.BACKGROUND')}>
                    {tabBackground}
                </Tab>
            </TabsPaneV2>
        </>
    );
}

export default SidebarContent;
