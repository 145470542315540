import { Detection, EmotionDetector } from '@iad-os/emotion-detector';
import React from 'react';
import Loader from '../../../../components/shared/loader/loader/Loader';
import './emotionview.scss';

interface EmotionViewParams {
    currentDetection: Detection | undefined;
    detecting: boolean;
    period: number;
    emoThreshold: number;
    onEmotionDetect: (detection: Detection) => void;
}

export const EmotionView = ({
    currentDetection,
    detecting,
    onEmotionDetect,
    period,
    emoThreshold,
}: EmotionViewParams) => {
    const className = 'emotion-view card-shadow' + (detecting ? '' : ' hide');
    return (
        <>
            <div className={className}>
                {!currentDetection && <Loader size="x50" style={{ zIndex: -1, position: 'absolute' }} />}
                <EmotionDetector
                    detecting={detecting}
                    onEmotionDetect={onEmotionDetect}
                    emotionDetectPeriod={period}
                    detectionPeriodMillis={1000} // must be fix to 1000ms
                    emoThreshold={emoThreshold}
                    showCam={false}
                    showLabel={true}
                    camSizeRatio={1}
                    labelFormat={'emoji'}
                    detectAge={false}
                    detectSex={false}
                    labelStyle={{
                        backgroundColor: 'cornflowerblue',
                        width: '80px',
                        height: '80px',
                        fontSize: '25px',
                        borderRadius: '50%',
                        margin: 0,
                    }}
                />
            </div>
        </>
    );
};
