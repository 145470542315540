import { useSelector } from 'react-redux';
import { selectAllQuestions } from '../../gameNextOperationSlice';
import { IAnswer, IQuestion } from '../../models/gameQuestionConfiguration';
import { getAllAnswersByQuestionId } from '../../services/gameConfiguration.service';

type Answer = IAnswer;

export function useCachedAnswers(): (
  questionId: string,
  LanguageId: string
) => Promise<Answer[]> {
  const questions: IQuestion[] = useSelector(selectAllQuestions);

  function cachedAnswers(
    questionId: string,
    languageId: string
  ): Promise<Answer[]> {
    const questionFound = questions.find(q => q.id === questionId);
    if (questionFound) {
      console.log('cache hit');
      const { questionItems } = questionFound;
      const correctAnswers = questionItems[0].correctAnswers.map(a => ({
        id: a.id,
        answer: a.answer,
      }));
      const wrongAnswers = questionItems[0].wrongAnswers.map(a => ({
        id: a.id,
        answer: a.answer,
      }));
      return new Promise(resolve => {
        resolve([...correctAnswers, ...wrongAnswers] as Answer[]);
      });
    }

    return getAllAnswersByQuestionId(questionId, languageId) as Promise<
      Answer[]
    >;
  }

  return cachedAnswers;
}
