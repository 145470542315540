import { Detection, DetectionStatus } from '@iad-os/emotion-detector';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EmotionView } from './components/EmotionView';
import { useEnableEmotions } from './hooks/useEnableEmotions';
import { DetectionModelAPI } from './models/DetectionModelAPI';
import { initDetection, sendDetectionEvent } from './services/emotions.service';

const DEFAULT_EMOTHRESHOLD = 60;
const DEFAULT_PERIOD = 5000; // must be >=1000

const Emotions = () => {
    const enableEmotions = useEnableEmotions();
    const location = useLocation();
    const [currentDetection, setCurrentDetection] = useState<Detection>();

    const [detection, setDetection] = useState({
        contentUrl: location.pathname,
        period: DEFAULT_PERIOD,
        emoThreshold: DEFAULT_EMOTHRESHOLD,
        status: DetectionStatus.STARTED,
    } as DetectionModelAPI);

    const changecurrentDetection = (contentUrl: string) => {
        const { _id, ...others } = detection; // remove _id
        const current = { ...others, contentUrl };
        setDetection(current);
        return current;
    };

    useEffect(() => {
        if (!currentDetection) {
            return;
        }
        // sendDetectionEventMock(detection._id, currentDetection.detectionEvent);
        sendDetectionEvent(detection._id, currentDetection.detectionEvent).then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDetection]);

    useEffect(() => {
        if (enableEmotions) {
            initDetection(changecurrentDetection(location.pathname)).then(res => {
                setDetection(res); // save _id
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, enableEmotions]);

    return (
        <>
            {
                <EmotionView
                    emoThreshold={DEFAULT_EMOTHRESHOLD}
                    period={DEFAULT_PERIOD}
                    currentDetection={currentDetection}
                    detecting={enableEmotions}
                    onEmotionDetect={(detection: Detection) => setCurrentDetection(detection)}
                />
            }
        </>
    );
};

export default Emotions;
