import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../app/auth/authSlice';
import { IMe } from '../../../../app/auth/model/User';
import Icon from '../../../../components/shared/icon/Icon';
import { isDocOrVideoOrCourseOrResources } from '../../../../utils/categoryUtils';
import { AnimateData } from '../../animations/models/types';
import { ISkill } from '../../game/gamePlatform/models/gameQuestionConfiguration';
import { ICategory } from '../../models/Categories';
import { IResource } from '../../resources/model/layout';
import { IAction, IParam, SubCategoryType } from '../models/type';
import { retriveAllParams } from '../service/animates-action.service';
import ActionForm from './ActionForm';

export interface CommonActionsParams {
    actions: IAction[];
    categories: ICategory[];
    skills: ISkill[];
    animationGames: AnimateData[];
    depthCategories?: ICategory;
    resourcesData: IResource[];
    deleteAction: (action: IAction) => void;
    submitAction: (action: IAction) => void;
    addElement: () => void;
}

export const CommonActions = ({
    actions,
    categories,
    skills,
    animationGames,
    depthCategories,
    resourcesData,
    deleteAction,
    submitAction,
    addElement,
}: CommonActionsParams) => {
    const [params, setParams] = useState<IParam>({
        trigger: [],
        event: [],
        goTo: [],
    });
    useEffect(() => {
        getParams();
    }, []);
    const user: IMe | undefined = useSelector(selectUser);
    const { t } = useTranslation();

    function getParams() {
        retriveAllParams().then(res => setParams({ trigger: res.trigger, event: res.event, goTo: res.goTo }));
    }

    function createSubCategory(goToCategoryArray: number[]) {
        const subCatObject: SubCategoryType[] = goToCategoryArray.map(item => {
            return { id: item };
        });

        return makeNestedObject(subCatObject);
    }

    function makeNestedObject(arr: SubCategoryType[]) {
        let nested: SubCategoryType = { id: 0 };
        for (let i = arr.length - 1; i >= 0; i--) {
            if (i === arr.length - 1) {
                //assegno il valore
                nested = { id: arr[i].id };
            } else {
                //assegno il valore precedente
                nested = { id: arr[i].id, subCategory: nested };
            }
        }
        return nested;
    }

    function fixDataLoad(data: IAction): IAction {
        //fixo i campi goToId e goToCateogryId e subItemId
        let fixedDataLoad = data;

        const goToCategoryId = data.goToCategoryId && `${data?.goToCategoryId[0]}`;
        const subItemId = data.goToCategoryId
            ? data.goToCategoryId.length > 1
                ? `${data.goToCategoryId[1]}`
                : undefined
            : undefined;
        const goToId = data.goToId ? data.goToId[0] : undefined;
        let category;
        if (isDocOrVideoOrCourseOrResources(data.goTo)) {
            category = createSubCategory(data.goToCategoryId as number[]);
            data = data.goToId ? { ...data, resourceId: data.goToId[0] } : data;
        }

        if (data.conditions) {
            const fixedConditions = data.conditions.map(condition => {
                const fixedAnswers = JSON.stringify(condition.answers);
                return { ...condition, answers: fixedAnswers };
            });
            fixedDataLoad = {
                ...data,
                goToCategoryId,
                subItemId,
                goToId,
                conditions: fixedConditions,
                category,
            };
        } else {
            fixedDataLoad = { ...data, goToCategoryId, subItemId, goToId, category };
        }
        return fixedDataLoad;
    }

    return (
        <div className="game-settings">
            {actions.map((ele, index) => (
                <ActionForm
                    action={fixDataLoad(ele)}
                    key={index}
                    categories={categories}
                    skills={skills}
                    goTo={params.goTo}
                    triggers={params.trigger}
                    events={params.event}
                    langId={user?.preference.language || 'it'}
                    submitActionHandler={submitAction}
                    deleteActionHandler={deleteAction}
                    animationGames={animationGames}
                    depthCategories={depthCategories}
                    resourcesData={resourcesData}
                />
            ))}
            <Row className="justify-content-end">
                <Col xs="auto">
                    <Icon
                        icon="add"
                        label={t('APP.ADD')}
                        title={t('APP.ADD')}
                        size="20px"
                        colorStyle="#01a381"
                        clickAction={addElement}
                    ></Icon>
                </Col>
            </Row>
        </div>
    );
};
