import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../../components/shared/design/form/buttons/primary-button/PrimaryButton';
import useGameConditionForm from '../../../../components/shared/game-condition/useGameConditionForm';
import {
    createCategoriesOption,
    createResourcesOption,
    findCategory,
    fixGoToCategoryIdData,
    isDocOrVideoOrCourseOrResources,
} from '../../../../utils/categoryUtils';
import FormElementOnlyOne from '../../../../utils/forms/FormElementOnlyOne';
import FormElements from '../../../../utils/forms/FormElements';
import { ValueName } from '../../../../utils/forms/models/DataForm';
import { OptionSelect } from '../../../../utils/forms/models/FormElementModel';
import { UseFormSubmitModel } from '../../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../../utils/forms/useForm';
import { getObjForm, setObjForm } from '../../../../utils/forms/useStructureForm';
import { AnimateData, AnimationType } from '../../animations/models/types';
import useCategories from '../../common/useCategories';
import { useRecursiveSelect } from '../../common/useRecursiveSelect';
import { navigationActionsName } from '../../data/navigationData';
import MessageTemplateGame from '../../game/gamePlatform/components/MessageTemplateGame';
import { ISkill } from '../../game/gamePlatform/models/gameQuestionConfiguration';
import { ICategory } from '../../models/Categories';
import { SubCategoriesStructureType } from '../../projectMap/adminPinSidebar/usePinForm';
import { IResource } from '../../resources/model/layout';
import { IAction, SubCategoryType } from '../models/type';
import useActionForm from './useActionForm';
import useTriggerForm from './useTriggerForm';
import { validations } from './ValidationData';

function ActionForm({
    action,
    goTo,
    categories,
    skills,
    triggers,
    events,
    langId,
    deleteActionHandler,
    submitActionHandler,
    animationGames,
    depthCategories,
    resourcesData,
}: {
    action: IAction;
    goTo: string[];
    categories: ICategory[];
    skills: ISkill[];
    triggers: string[];
    events: string[];
    langId: string;
    deleteActionHandler: (action: IAction) => void;
    submitActionHandler: (action: IAction) => void;
    animationGames: AnimateData[];
    depthCategories?: ICategory;
    resourcesData: IResource[];
}) {
    const { t } = useTranslation();
    const [selectedGoTo, setselectedGoTo] = useState<string>('');
    const [animates, setAnimates] = useState<AnimationType[]>([]);
    const { subcategoriesList } = useRecursiveSelect(selectedGoTo);
    const [resources, setResources] = useState<IResource[]>([]);

    const submitHandler: UseFormSubmitModel = (event, data: any) => {
        submitActionHandler(data);
    };

    const optionCategory = (cat?: ICategory, _default: boolean = true): OptionSelect[] => {
        if (cat && cat.children) {
            const opts = _default
                ? [
                      {
                          label: t('SUBCATEGORY.PLACEHOLDER'),
                          value: '-1',
                      },
                  ]
                : [];
            return [...opts, ...createCategoriesOption(cat.children)];
        } else {
            return [];
        }
    };

    const validationsData = validations(action);
    const form = useForm(submitHandler, validationsData);
    const { isSubmit, errors, submitAction, changeValue, dataForm, setData, deleteElementByArray, dataFormatted } =
        form;

    const getCategories = (goToAction: string, categories: ICategory[]) => {
        if (isDocOrVideoOrCourseOrResources(goToAction)) {
            return depthCategories ? [depthCategories] : [];
        } else {
            return categories;
        }
    };

    const { getCatOption } = useCategories(selectedGoTo);

    const {
        categoryIdAndProgressiveForm,
        goToForm,
        goToAnimationForm,
        goToAnimationProgressiveForm,
        goToCategoryForm,
        subItemIdForm,
        dataSubcategoriesStructure,
        resourcesDataForm,
        onSubcategoryChange,
        createSubCategoriesStructure,
    } = useActionForm(
        t,
        dataForm,
        goTo,
        getCategories(selectedGoTo, categories),
        animationGames,
        animates,
        createResourcesOption(resources),
        getCategoryOptions(),
        subcategoriesList
    );

    const { gameConditionStructure } = useGameConditionForm(
        t,
        dataFormatted,
        changeValue,
        dataForm,
        errors,
        isSubmit,
        categories,
        skills,
        langId
    );

    const { triggerDataStructure } = useTriggerForm(
        t,
        dataForm,
        triggers,
        events,
        dataFormatted,
        errors,
        changeValue,
        isSubmit,
        deleteElementByArray
    );

    const onSubCategoryStructureDataChange = ({ value, name }: ValueName) => {
        const category = findCategory(value, depthCategories);
        let newName = '';
        let newDataForm = null;

        if (category) {
            const options = optionCategory(category)!;
            newName = Array(category.depth - 1)
                .fill(0)
                .reduce(acc => `${acc}___subCategory`, 'subCategory');
            const newDataFormatted = onSubcategoryChange({
                name: newName,
                options,
                parent: { name, value },
                label: category.name,
            });
            newDataForm = fixGoToCategoryIdData(
                getObjForm({
                    ...newDataFormatted,
                    [name]: value,
                }),
                selectedGoTo
            );
        } else {
            changeValue({ name, value });
            newDataForm = fixGoToCategoryIdData(
                getObjForm({
                    ...dataForm,
                    [name]: value,
                }),
                selectedGoTo
            );
        }
        setData(newDataForm);
        value && selectedGoTo && getResources(value, getType(selectedGoTo));
        return newName;
    };

    useEffect(() => {
        if (action) {
            const toForm = setObjForm(fixForm(action));
            if (!isEqual(toForm, dataForm)) {
                setData(toForm); //questa riga genera un warning fastidioso!!!
            }
            action.goTo && setselectedGoTo(action.goTo);
            depthCategories && editMode(action, depthCategories);
            initResources(action);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    useEffect(() => {
        getAnimates(dataForm['goToCategoryId']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm]);

    function initResources(action: IAction) {
        if (isDocOrVideoOrCourseOrResources(action?.goTo)) {
            //recupero le risorse dall'ultima subCategory
            const subCatId = getLastChildren(action.category);
            subCatId && action.resourceId && getResources(`${subCatId}`, getType(action.goTo));
        }
    }

    function getLastChildren(categoryObj?: SubCategoryType): any {
        if (categoryObj && categoryObj.hasOwnProperty('subCategory')) {
            return getLastChildren(categoryObj.subCategory);
        } else {
            return categoryObj?.id;
        }
    }

    function fixForm(dataForm: { [key: string]: any }) {
        let fixedDataForm: { [key: string]: any } = {};
        // eslint-disable-next-line array-callback-return
        Object.keys(dataForm).map(key => {
            if (key.startsWith('trigger')) {
                const field = key.replace('trigger', '');
                fixedDataForm[`trigger___0${field}`] = dataForm[key];
            } else if (key.startsWith('goToCategoryId')) {
                const field = 'goToCategoryId';
                fixedDataForm[field] = dataForm[key];
            } else if (key.startsWith('goToId')) {
                const field = 'goToId';
                fixedDataForm[field] = dataForm[key];
            } else if (key.match('category___subCategory___subCategory___id')) {
                const field = 'category___subCategory___id';
                fixedDataForm[field] = `${dataForm[key]}`;
            } else if (key.startsWith('category___')) {
                fixedDataForm[key] = `${dataForm[key]}`;
            } else {
                fixedDataForm[key] = dataForm[key];
            }
        });

        return fixedDataForm;
    }

    function editMode(selectedAction: IAction, _depthCategories: ICategory) {
        function buildSubcategoriesForm(
            _category: SubCategoryType | undefined,
            data: SubCategoriesStructureType[] = []
        ): SubCategoriesStructureType[] {
            if (!_category || !_category?.id) {
                return data;
            }
            const category = findCategory(_category.id.toString(), _depthCategories);
            if (!category || (category.children && category.children.length === 0)) {
                return data;
            }
            const options = optionCategory(category)!;
            const newName = Array(category.depth - 1)
                .fill(0)
                .reduce((acc, curr) => (!acc ? 'subCategory' : `${acc}___subCategory`), null);
            return buildSubcategoriesForm(_category.subCategory, [
                ...data,
                { options, name: newName, label: category?.name! },
            ]);
        }

        if (_depthCategories && isDocOrVideoOrCourseOrResources(selectedAction.goTo)) {
            createSubCategoriesStructure(buildSubcategoriesForm(selectedAction.category, []));
        }
    }

    function getAnimates(value: string) {
        const animationsFiltered = animationGames.find(
            item => Number.parseInt(item.animateId!) === Number.parseInt(value)
        );
        //setto gli episodi corrispondenti all'animation selezionata
        animationsFiltered && setAnimates(animationsFiltered.animates);
    }

    function getCategoryOptions() {
        if (isDocOrVideoOrCourseOrResources(selectedGoTo)) {
            return optionCategory(depthCategories, false);
        }
        return createCategoriesOption(getCatOption());
    }

    function updateSubcategory(subItemId: string) {
        const subs = subItemId ? depthCategories?.children?.find(c => c.id.toString() === subItemId) : undefined;
        for (const key in dataForm) {
            if (key.includes('subCategory') || key.includes('subItemId')) {
                delete dataForm[key];
            }
        }
        if (subs) {
            createSubCategoriesStructure([
                {
                    options: optionCategory(subs),
                    label: subs.name,
                    name: 'subCategory',
                } as SubCategoriesStructureType,
            ]);
        }
    }

    function handleDataFormChange({ value, name }: ValueName) {
        changeValue({ value, name });
    }

    function changeValueEnd({ value, name }: ValueName) {
        changeValue({ value, name });
        if (
            (name === 'subItemId' || 'goToCategoryId' || name.startsWith('category___subCategory')) &&
            isDocOrVideoOrCourseOrResources(selectedGoTo)
        ) {
            updateSubcategory(value);
            const df = fixGoToCategoryIdData(
                getObjForm({
                    ...dataForm,
                    [name]: value,
                }),
                selectedGoTo
            );
            setData(df);
            getResources(value, getType(selectedGoTo));
        } else {
            setResources([]);
        }
    }

    function omitGoToFieldsFromDataForm(...args: string[]) {
        let keys: string[] = [];
        args.forEach(arg => [...keys, Object.keys(dataForm).filter(key => key.startsWith(arg))]);
        return keys;
    }

    function onGoToSelectChange({ value, name }: ValueName) {
        // ** 1) change goTo: cambio il goTo => resetto tutte le combo
        //salvo il tipo di goTo selezionato
        setselectedGoTo(value);
        changeValue({ value, name });
        //tolgo i campi goToCategory e goToId dal dataForm per resettare i valori a undefined
        resetDataForm(name, value);
    }

    function onCategoryIdSelectChange({ value, name }: ValueName) {
        // ** 2) change goToCategoryId: salvo la categoryId nel campo array goToCategoryId
        changeValueEnd({ value, name });
        if (isDocOrVideoOrCourseOrResources(selectedGoTo)) {
            onSubCategoryStructureDataChange({ value, name });
            getResources(value, getType(selectedGoTo));
        } else {
            setResources([]);
        }
        resetDataForm(name, value);
    }

    function getType(goToAction: string) {
        const type = goToAction.replace('goTo', '').toLowerCase();
        return type;
    }

    function resetDataForm(name: string, value: any) {
        setData({
            ...omit(dataForm, omitGoToFieldsFromDataForm('goToCategoryId', 'goToId', 'category___', 'subItemId')),
            ...dataForm,
            [name]: value,
        });
    }

    function onGoToIdSelectChange({ value, name }: ValueName) {
        // ** 3) change goToId (id progressivo oppure subcat)
        changeValue({ value, name: 'goToId' });
        setData({ ...dataForm, [name]: value, goToId: value });
    }

    function onAnimationIdSelectChange({ value, name }: ValueName) {
        // ** 2) change AnimationId: salvo sempre in goToCategoryId
        getAnimates(value);
        //resetto il campo goToId
        setData(omit(dataForm, omitGoToFieldsFromDataForm('goToId')));
        onCategoryIdSelectChange({ value, name });
    }

    function getResources(categoryId: string, type: string) {
        // eslint-disable-next-line array-callback-return
        const filtered = resourcesData.filter(res => {
            const catId = res.categories?.find(id => id === Number(categoryId));
            if (catId) {
                return res;
            }
        });
        filtered && setResources(filtered);
    }

    return (
        <MessageTemplateGame
            title={`${t('ANIMATION.ACTION.TITLE')} n. ${action.progressive}`}
            deleteElementArray={() => deleteActionHandler(action)}
            key={action?._id}
        >
            <div className="nested-accordion" key={`form-${action?._id}`}>
                <Form onSubmit={submitAction}>
                    {action && (
                        <div style={{ alignContent: 'center', margin: '0px' }}>
                            <FormElements
                                data={categoryIdAndProgressiveForm}
                                dataFormatted={dataFormatted}
                                errors={errors}
                                isSubmit={isSubmit}
                                dataForm={dataForm}
                                changeValue={handleDataFormChange}
                            />
                            <FormElements
                                data={goToForm}
                                dataFormatted={dataFormatted}
                                errors={errors}
                                isSubmit={isSubmit}
                                dataForm={dataForm}
                                changeValue={onGoToSelectChange}
                            />

                            {selectedGoTo &&
                                selectedGoTo !== navigationActionsName.goToAnimations &&
                                !isDocOrVideoOrCourseOrResources(selectedGoTo) && (
                                    <FormElements
                                        data={goToCategoryForm}
                                        dataFormatted={dataFormatted}
                                        errors={errors}
                                        isSubmit={isSubmit}
                                        dataForm={dataForm}
                                        changeValue={onCategoryIdSelectChange}
                                    />
                                )}

                            {selectedGoTo === navigationActionsName.goToAnimations && (
                                <>
                                    <FormElements
                                        data={goToAnimationForm}
                                        dataFormatted={dataFormatted}
                                        errors={errors}
                                        isSubmit={isSubmit}
                                        dataForm={dataForm}
                                        changeValue={onAnimationIdSelectChange}
                                    />
                                    <FormElements
                                        data={goToAnimationProgressiveForm}
                                        dataFormatted={dataFormatted}
                                        errors={errors}
                                        isSubmit={isSubmit}
                                        dataForm={dataForm}
                                        changeValue={onGoToIdSelectChange}
                                    />
                                </>
                            )}

                            {isDocOrVideoOrCourseOrResources(selectedGoTo) && (
                                <>
                                    <FormElements
                                        data={goToCategoryForm}
                                        errors={errors}
                                        isSubmit={true}
                                        dataForm={dataForm}
                                        changeValue={changeValueEnd}
                                    />
                                    <FormElements
                                        data={subItemIdForm}
                                        errors={errors}
                                        isSubmit={true}
                                        dataForm={dataForm}
                                        changeValue={changeValueEnd}
                                    />
                                    {selectedGoTo !== navigationActionsName.goToResources && (
                                        <>
                                            <FormElements
                                                data={dataSubcategoriesStructure}
                                                dataFormatted={getObjForm(dataForm)}
                                                errors={errors}
                                                isSubmit={isSubmit}
                                                dataForm={dataForm}
                                                changeValue={onSubCategoryStructureDataChange}
                                                deleteElementByArray={deleteElementByArray}
                                            />
                                            <FormElements
                                                data={resourcesDataForm}
                                                errors={errors}
                                                isSubmit={isSubmit}
                                                dataForm={dataForm}
                                                changeValue={handleDataFormChange} //{changeValueEnd}
                                            />
                                        </>
                                    )}
                                </>
                            )}

                            <FormElementOnlyOne
                                data={triggerDataStructure}
                                dataFormatted={dataFormatted}
                                errors={errors}
                                isSubmit={isSubmit}
                                dataForm={dataForm}
                                changeValue={handleDataFormChange}
                                deleteElementByArray={deleteElementByArray}
                                className={'mr-0 ml-0 mt-2'}
                            />
                            <FormElements
                                data={gameConditionStructure}
                                dataFormatted={dataFormatted}
                                errors={errors}
                                isSubmit={isSubmit}
                                dataForm={dataForm}
                                changeValue={handleDataFormChange}
                                deleteElementByArray={deleteElementByArray}
                                className={'mr-0 ml-0 mt-2'}
                            />
                        </div>
                    )}
                    <div className={`my-2 d-flex`} style={{ justifyContent: 'space-evenly' }}>
                        <PrimaryButton>{t('APP.SAVE')}</PrimaryButton>
                    </div>
                </Form>
            </div>
        </MessageTemplateGame>
    );
}

export default ActionForm;
